import { __assign, __awaiter, __generator, __spreadArrays } from "tslib";
import Vue from "vue";
import { UserService } from "@/services/models/user/user";
import Loader from "@/components/customComponents/Loader.vue";
import Toast from "@/components/customComponents/Toast.vue";
import { formatRut } from "@/thirdParty/utils";
import { mapMutations } from "vuex";
import store from "@/store";
import { LocationService } from "@/services/models/user/location";
import { StablishmentService } from "@/services/models/stablishment";
var DIRECCION = 1;
var SUBDIRECCION = 2;
var DEPARTAMENTO = 3;
var UNIDAD = 4;
export default Vue.extend({
    components: { Loader: Loader, Toast: Toast },
    props: {
        bgColor: {
            type: String,
            default: "red"
        },
        fgColor: {
            type: String,
            default: "white"
        }
    },
    data: function () {
        return {
            step: 1,
            passwordLogin: "",
            rutLogin: "",
            snackbar: false,
            items: ["DSSM", "HCM"],
            loading: false,
            username: "",
            pather_lastname: "",
            mother_lastname: "",
            rut: "",
            email: "",
            anexo: "",
            stablishmentSelected: {},
            stablishments: [],
            loadingStablishment: false,
            directionSelected: {},
            directions: [],
            loadingDirection: false,
            subdirectionSelected: {},
            subdirections: [],
            loadingSubdirection: false,
            departmentSelected: {},
            departments: [],
            loadingDepartment: false,
            unitySelected: {},
            unities: [],
            loadingUnity: false,
            usersLocations: []
            // %0D%0A
        };
    },
    beforeCreate: function () {
        document.body.className = "intro";
    },
    mounted: function () {
        this.loadStablishment();
    },
    methods: __assign(__assign({}, mapMutations({
        setProfile: "userSession/setProfile",
        setCurrentLocation: "userSession/setCurrentLocation"
    })), { requestCreateAccount: function () {
            var content = "\n          DATOS DE LA CUENTA %0D%0A %0D%0A\n          Nombre: " + this.username + "  %0D%0A\n          Primer apellido: " + this.pather_lastname + "  %0D%0A\n          Segundo apellido: " + this.mother_lastname + "  %0D%0A\n          Rut: " + this.rut + "  %0D%0A\n          Correo institucional: " + this.email + "@redsalud.gov.cl  %0D%0A\n          Establecimiento: " + this.stablishmentSelected.name + "  %0D%0A\n          Subdirecci\u00F3n: " + this.subdirectionSelected.name + "  %0D%0A\n          Departamento: " + this.departmentSelected.name + "  %0D%0A\n          Unidad: " + this.unitySelected.name + "  %0D%0A\n          Anexo: " + this.anexo + "  %0D%0A\n        ";
            window.open("https://outlook.office.com/owa/?path=/mail/action/compose&to=tic.ssmagallanes@redsalud.gov.cl&subject=Crear usuario FENIX &body=" + content, "_blank" // <- This is what makes it open in a new window.
            );
        },
        formatRut: function () {
            this.rutLogin = formatRut(this.rutLogin);
        },
        connect: function () {
            var _a, _b;
            return __awaiter(this, void 0, void 0, function () {
                var data, response, _c, error_1;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0:
                            this.displayLoader(true);
                            data = {
                                run: this.rutLogin.replaceAll(".", ""),
                                password: this.passwordLogin,
                                grant_type: "password"
                            };
                            _d.label = 1;
                        case 1:
                            _d.trys.push([1, 11, 12, 13]);
                            store.commit("relogin/resetRelogin");
                            return [4 /*yield*/, UserService.login(data)];
                        case 2:
                            response = _d.sent();
                            localStorage.setItem("token", response.data.token);
                            localStorage.setItem("user", JSON.stringify(response.data.user));
                            localStorage.setItem("perfil", ((_a = response.data.user.role) === null || _a === void 0 ? void 0 : _a.name) || "");
                            this.setProfile((_b = response.data.user.role) === null || _b === void 0 ? void 0 : _b.name);
                            if (response.data.user.users_locations) {
                                this.setCurrentLocation(response.data.user.users_locations[0]);
                            }
                            store.commit("userSession/setUserModules");
                            store.commit("userSession/setUserPermissions");
                            _c = response.data.user.role_id;
                            switch (_c) {
                                case 1: return [3 /*break*/, 3];
                                case 2: return [3 /*break*/, 5];
                                case 3: return [3 /*break*/, 7];
                            }
                            return [3 /*break*/, 9];
                        case 3: //Administrator
                        return [4 /*yield*/, this.$router.push({ name: "administratorHome" })];
                        case 4:
                            _d.sent();
                            return [3 /*break*/, 10];
                        case 5: //Administrativo
                        return [4 /*yield*/, this.$router.push({ name: "administrativeHome" })];
                        case 6:
                            _d.sent();
                            return [3 /*break*/, 10];
                        case 7: //Opartes
                        return [4 /*yield*/, this.$router.push({ name: "opartesHome" })];
                        case 8:
                            _d.sent();
                            return [3 /*break*/, 10];
                        case 9: return [3 /*break*/, 10];
                        case 10: return [3 /*break*/, 13];
                        case 11:
                            error_1 = _d.sent();
                            if (error_1.message == "Request failed with status code 401") {
                                this.displayToast({
                                    type: "warning",
                                    message: "Las credenciales ingresadas son incorrectas"
                                });
                            }
                            else {
                                this.displayToast({
                                    type: "error",
                                    message: "Ha ocurrido un error al ingresar, favor volver a intentar"
                                });
                            }
                            return [3 /*break*/, 13];
                        case 12:
                            this.displayLoader(false);
                            return [7 /*endfinally*/];
                        case 13: return [2 /*return*/];
                    }
                });
            });
        },
        loadStablishment: function () {
            return __awaiter(this, void 0, Promise, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            this.loadingStablishment = true;
                            _a = this;
                            return [4 /*yield*/, StablishmentService.getAllStablishmentsPublic()];
                        case 1:
                            _a.stablishments = _b.sent();
                            this.loadingStablishment = false;
                            return [2 /*return*/];
                    }
                });
            });
        },
        loadDirections: function () {
            return __awaiter(this, void 0, Promise, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            this.loadingDirection = true;
                            _a = this;
                            return [4 /*yield*/, LocationService.getByLocationTypeStablishmentPublic(DIRECCION, this.stablishmentSelected.id || 0)];
                        case 1:
                            _a.directions = _b.sent();
                            this.subdirections = [];
                            this.departments = [];
                            this.unities = [];
                            this.loadingDirection = false;
                            return [2 /*return*/];
                    }
                });
            });
        },
        loadSubdirections: function () {
            return __awaiter(this, void 0, Promise, function () {
                var subdirectionsReponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.loadingSubdirection = true;
                            this.subdirections = [];
                            return [4 /*yield*/, LocationService.getByParentLocationTypeStablishmentPublic(SUBDIRECCION, this.stablishmentSelected.id || 0, this.directionSelected.id || 0)];
                        case 1:
                            subdirectionsReponse = _a.sent();
                            if (subdirectionsReponse.length) {
                                //En caso que no se quiera elegir subdirección
                                this.subdirections = __spreadArrays([
                                    {
                                        id: -1,
                                        name: "Sin subdirección",
                                        location_type_id: 0,
                                        stablishment_id: 0
                                    }
                                ], subdirectionsReponse);
                            }
                            this.departments = [];
                            console.log(this.departments);
                            this.unities = [];
                            this.loadingSubdirection = false;
                            return [2 /*return*/];
                    }
                });
            });
        },
        loadDepartments: function () {
            return __awaiter(this, void 0, Promise, function () {
                var departmentsResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.loadingDepartment = true;
                            console.log(this.stablishmentSelected);
                            this.departments = [];
                            return [4 /*yield*/, LocationService.getByParentLocationTypeStablishmentPublic(DEPARTAMENTO, this.stablishmentSelected.id || 0, this.subdirectionSelected.id)];
                        case 1:
                            departmentsResponse = _a.sent();
                            if (departmentsResponse.length) {
                                //En caso que no se quiera elegir departament
                                this.departments = __spreadArrays([
                                    {
                                        id: -1,
                                        name: "Sin departamento",
                                        location_type_id: 0,
                                        stablishment_id: 0
                                    }
                                ], departmentsResponse);
                            }
                            this.unities = [];
                            this.loadingDepartment = false;
                            return [2 /*return*/];
                    }
                });
            });
        },
        loadUnities: function () {
            return __awaiter(this, void 0, Promise, function () {
                var unitiesResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.loadingUnity = true;
                            this.unities = [];
                            return [4 /*yield*/, LocationService.getByParentLocationTypeStablishmentPublic(UNIDAD, this.stablishmentSelected.id || 0, this.departmentSelected.id)];
                        case 1:
                            unitiesResponse = _a.sent();
                            if (unitiesResponse.length) {
                                //En caso que no se quiera elegir unity
                                this.unities = __spreadArrays([
                                    {
                                        id: -1,
                                        name: "Sin unidad",
                                        location_type_id: 0,
                                        stablishment_id: 0
                                    }
                                ], unitiesResponse);
                            }
                            this.loadingUnity = false;
                            return [2 /*return*/];
                    }
                });
            });
        } })
});
