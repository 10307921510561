import { __assign, __awaiter, __generator } from "tslib";
import Vue from "vue";
import { PurchaseRequestStateService } from "@/services/models/purchaseRequest/purchaseRequestState";
import { PurchaseHistoryService } from "@/services/models/purchaseRequest/purchaseHistory";
export default Vue.extend({
    props: {
        purchaseProp: {
            type: Object,
        },
        dataSend: {
            type: Object,
        },
        recharge: {
            type: Function
        }
    },
    data: function () {
        return {
            dialog: false,
            isLoading: false
        };
    },
    methods: {
        save: function () {
            return __awaiter(this, void 0, void 0, function () {
                var PURCHASE_REQUEST_STATES, RESULT_ARRAY_STATE_PENDING, RESULT_ARRAY_USER_STATE, e_1;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.isLoading = true;
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 8, 9, 10]);
                            return [4 /*yield*/, PurchaseRequestStateService.getByPurchaseRequestId(this.purchaseProp.id)];
                        case 2:
                            PURCHASE_REQUEST_STATES = _a.sent();
                            RESULT_ARRAY_STATE_PENDING = PURCHASE_REQUEST_STATES.filter(function (data) {
                                if (data.state == _this.dataSend.state && data.step == _this.dataSend.step) {
                                    return data;
                                }
                            });
                            RESULT_ARRAY_USER_STATE = PURCHASE_REQUEST_STATES.filter(function (data) {
                                if (data.user_id == _this.getCurrentUserLoggedIn().id) {
                                    return data;
                                }
                            });
                            if (!(RESULT_ARRAY_STATE_PENDING.length != 0)) return [3 /*break*/, 6];
                            return [4 /*yield*/, PurchaseRequestStateService.remove(RESULT_ARRAY_STATE_PENDING[0].id)];
                        case 3:
                            _a.sent();
                            return [4 /*yield*/, PurchaseRequestStateService.update(RESULT_ARRAY_USER_STATE[0].id, __assign(__assign({}, RESULT_ARRAY_USER_STATE[0]), { state: 'pendiente' }))];
                        case 4:
                            _a.sent();
                            return [4 /*yield*/, PurchaseHistoryService.create({
                                    purchase_request_id: this.purchaseProp.id,
                                    state: 'Solicitud retrotraida',
                                    user_origin_id: this.getCurrentUserLoggedIn().id,
                                    user_destination_id: RESULT_ARRAY_STATE_PENDING[0].user_id
                                })];
                        case 5:
                            _a.sent();
                            _a.label = 6;
                        case 6: return [4 /*yield*/, this.recharge()];
                        case 7:
                            _a.sent();
                            this.displayToast({
                                type: 'success',
                                title: 'La solicitud volvió sin problemas',
                                message: 'La solicitud ahora debería estar en su bandeja de pendientes'
                            });
                            this.dialog = false;
                            return [3 /*break*/, 10];
                        case 8:
                            e_1 = _a.sent();
                            console.log(e_1);
                            this.displayToast({
                                type: 'error',
                                title: 'Hubo un error al traer la solicitud',
                                message: 'Favor de intentar más tarde'
                            });
                            return [3 /*break*/, 10];
                        case 9:
                            this.isLoading = false;
                            return [7 /*endfinally*/];
                        case 10: return [2 /*return*/];
                    }
                });
            });
        }
    }
});
