<template>
  <transition name="fade">
    <div
      v-if="show || showToast"
      style="
        position: fixed;
        top: 9% !important;
        z-index: 99999999999999;
        right: 2%;
      "
      @click="clearInterval"
      class="notify-container"
    >
      <div :class="`notify `">
        <h1>
          <span class="alerticon"
            ><img alt="" :src="`/${type}.png`"
          /></span>
          {{ title }}
        </h1>
        <p>{{ message }}</p>
        <div
          :class="`barloader ${type}`"
          :style="`margin-left:${lengthBar}%`"
        ></div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  data() {
    return {
      show: false,
      type: null,
      title: null,
      message: null,
      lengthBar: -5,
      interval: null,
      milliseconds: null,
    };
  },
  methods: {
    clearInterval() {
      clearInterval(this.interval);
      this.show = false;
      this.$store.state.toast.showToast = false;
    },
    startInterval() {
      let intervalTime = this.milliseconds / 107;
      this.interval = setInterval(() => {
        this.lengthBar += 1;
      }, intervalTime);
    },
  },
  computed: {
    showToast: {
      get() {
        if (this.$store.getters["toast/getShowToast"]) {
          //No se deben mutar cosas en un computed, pero es para usar vuex y eventBus al mismo tiempo. Modificar después
          /* eslint-disable */
          this.lengthBar = -5;
          clearInterval(this.interval);
          this.milliseconds = this.$store.state.toast.toastData.milliseconds;
          this.type = this.$store.state.toast.toastData.type;
          this.title = this.$store.state.toast.toastData.title;
          this.message = this.$store.state.toast.toastData.message;
          this.startInterval();
          /* eslint-enable */
        }
        return this.$store.getters["toast/getShowToast"];
      },
      set() {
        console.log('asd');
      }
    },
  },
  watch: {
    lengthBar() {
      if (this.lengthBar >= 110) {
        this.clearInterval();
        this.interval = null;
        this.show = false;
      }
    },
  },  
};
</script>
<style scoped>
/* .message{
     box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
     width: 30em;
} */
strong {
  font-weight: bold;
}
img {
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  width: 30px;
}
/** H1 **/
h1 {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1em;
  line-height: 1.5em;
  margin: 0px;
  margin-bottom: 20px;
  color: #444;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
}
h1:before,
h1:after {
  content: "";
  position: relative;
  display: inline-block;
  width: 50%;
  height: 1px;
  vertical-align: middle;
  background: #f0f0f0;
}
h1:before {
  left: -0.5em;
  margin: 0 0 0 -50%;
}
h1:after {
  left: 0.5em;
  /*margin: 0 -50% 0 0;*/
}
h1 > span {
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
}
p {
  display: block;
  line-height: 1.5em;
  margin-bottom: 10px;
  font-size: 13px;
}
/** notifications **/
.notify {
  display: block;
  background: #fff;
  padding: 7px 19px;
  max-width: 40em;
  min-width: 20em;
  margin: 0 auto;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 5px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding-bottom: 0;
}
.notify h1 {
  margin-bottom: 0px;
}
.notify .alerticon {
  margin-bottom: 5px;
}
/* Success */
.success h1 {
  color: #5fad4d;
}

/* Error */
.error h1 {
  color: #cc4733;
}

/* Warning */
.warning h1 {
  color: #eda93b;
}

/* Info */
.info h1 {
  color: #6cc8d4;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.barloader {
  background-color: red;
  height: 5px;
  margin-left: -5%;
  margin-right: -5%;
}
.barloader.success {
  background-color: #5fad4d;
}
.barloader.error {
  background-color: #cc4733;
}
.barloader.warning {
  background-color: #eda93b;
}
.barloader.info {
  background-color: #6cc8d4;
}
.notify-container:hover {
  cursor: pointer;
}
</style>
