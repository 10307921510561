import { __assign, __awaiter, __extends, __generator } from "tslib";
import { BaseService } from '../../base';
var DocumentDetailService = /** @class */ (function (_super) {
    __extends(DocumentDetailService, _super);
    function DocumentDetailService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(DocumentDetailService, "entity", {
        get: function () {
            return 'document_detail';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DocumentDetailService, "responseName", {
        get: function () {
            return 'document_detail';
        },
        enumerable: false,
        configurable: true
    });
    DocumentDetailService.getByDocumentId = function (document_id) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        path: "find_by_document_id/" + document_id
                    })];
            });
        });
    };
    DocumentDetailService.downloadFile = function (fileId) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        path: "download_image/" + fileId,
                        showFullResponse: true,
                        config: {
                            responseType: 'blob',
                            "Content-Type": "application/json"
                        }
                    })];
            });
        });
    };
    DocumentDetailService.getFileFirmed = function (dataToPost) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'put',
                        data: __assign(__assign({}, dataToPost), { api_token_key: "31602029-ffc8-4112-8bf7-16d028fe3635" }),
                        path: 'get_file_firmed',
                        showFullResponse: true
                    })];
            });
        });
    };
    DocumentDetailService.updateImage = function (dataToUpdate) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'put',
                        data: {
                            base64: "data:application/pdf;base64," + dataToUpdate.base64,
                        },
                        path: 'update_image/' + dataToUpdate.documentDetailId,
                        showFullResponse: true
                    })];
            });
        });
    };
    DocumentDetailService.getByDocumentIdByUser = function (document_id) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        path: "find_by_document_id_user/" + document_id
                    })];
            });
        });
    };
    DocumentDetailService.validate = function (document_id, verification_code) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        isFullPath: true,
                        path: this.entity + "/validate/" + document_id + "/" + verification_code
                    })];
            });
        });
    };
    return DocumentDetailService;
}(BaseService()));
export { DocumentDetailService };
