import Vue from "vue";
export default Vue.extend({
    props: {
        document_detail: {
            required: true,
            type: Object
        }
    },
    data: function () {
        return {};
    },
    computed: {
        stateDocument: function () {
            var result;
            if (this.document_detail.documents_details_firmed.length != 0 && this.document_detail.documents_details_firmed[0].state == 'firmado' && this.document_detail.state != 'invalido') {
                result = 'firmado';
            }
            else {
                result = this.document_detail.state;
            }
            return result;
        }
    }
});
