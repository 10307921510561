import { __awaiter, __extends, __generator } from "tslib";
import { BaseService } from '../../base';
var LocationService = /** @class */ (function (_super) {
    __extends(LocationService, _super);
    function LocationService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(LocationService, "entity", {
        get: function () {
            return 'location';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LocationService, "responseName", {
        get: function () {
            return 'location';
        },
        enumerable: false,
        configurable: true
    });
    LocationService.getByStablishmentId = function (stablishment_id) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        path: "find_by_stablishment/" + stablishment_id
                    })];
            });
        });
    };
    LocationService.getByLocationTypeStablishment = function (location_type_id, stablishment_id) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        path: "find_by_location_stablishment/" + location_type_id + "/" + stablishment_id
                    })];
            });
        });
    };
    LocationService.getByLocationTypeStablishmentPublic = function (location_type_id, stablishment_id) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        isFullPath: true,
                        path: this.entity + "/find_by_location_stablishment/" + location_type_id + "/" + stablishment_id
                    })];
            });
        });
    };
    LocationService.getByParentLocationTypeStablishment = function (location_type_id, stablishment_id, parent_id) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        isFullPath: true,
                        path: this.profile + "/" + this.entity + "/find_by_parent_location_stablishment/" + parent_id + "/" + location_type_id + "/" + stablishment_id,
                    })];
            });
        });
    };
    LocationService.getByParentLocationTypeStablishmentPublic = function (location_type_id, stablishment_id, parent_id) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.submit({
                        method: 'get',
                        isFullPath: true,
                        path: this.entity + "/find_by_parent_location_stablishment/" + parent_id + "/" + location_type_id + "/" + stablishment_id,
                    })];
            });
        });
    };
    return LocationService;
}(BaseService()));
export { LocationService };
