import { __assign, __awaiter, __generator } from "tslib";
import { DocumentService } from "@/services/models/document/document";
import store from "./../../store";
var moduleDocument = {
    namespaced: true,
    state: {
        Documents: [],
        myDocument: {
            data: [],
            total: 0,
            per_page: 0,
            current_page: 1,
        },
        requestDocument: {
            data: [],
            total: 0,
            per_page: 0,
            current_page: 1,
        },
        byCheckDocument: {
            data: [],
            total: 0,
            per_page: 0,
            current_page: 1,
        },
        CheckDocument: {
            data: [],
            total: 0,
            per_page: 0,
            current_page: 1,
        },
        noRelevantDocument: {
            data: [],
            total: 0,
            per_page: 0,
            current_page: 1,
        },
        byFirmDocument: {
            data: [],
            total: 0,
            per_page: 0,
            current_page: 1,
        },
        firmDocument: {
            data: [],
            total: 0,
            per_page: 0,
            current_page: 1,
        },
        rejectDocument: {
            data: [],
            total: 0,
            per_page: 0,
            current_page: 1,
        },
        searchDocument: {
            data: [],
            total: 0,
            per_page: 0,
            current_page: 1,
        },
        folderDocument: {
            data: [],
            total: 0,
            per_page: 0,
            current_page: 1,
        },
        filterSearchAdvanced: {},
        loading: false,
        requestedDocumentsNotRead: 0
    },
    getters: {
        getDocuments: function (state) {
            return state.Documents;
        },
        getMyDocument: function (state) {
            return state.myDocument;
        },
        getRequestDocument: function (state) {
            return state.requestDocument;
        },
        //Visaciones
        getByCheckedDocument: function (state) {
            return state.byCheckDocument;
        },
        getCheckedDocument: function (state) {
            return state.CheckDocument;
        },
        getNotRelevantDocument: function (state) {
            return state.noRelevantDocument;
        },
        //Firm
        getByFirmedDocument: function (state) {
            return state.byFirmDocument;
        },
        getFirmedDocument: function (state) {
            return state.firmDocument;
        },
        getRejectDocument: function (state) {
            return state.rejectDocument;
        },
        //searchDocument
        getSearchDocument: function (state) {
            return state.searchDocument;
        },
        getSearchAdvanced: function (state) {
            return state.filterSearchAdvanced || {};
        },
        //TableFolder
        getFolderDocument: function (state) {
            return state.folderDocument;
        },
        getLoading: function (state) {
            return state.loading;
        },
        getRequestedDocumentsNotRead: function (state) {
            return state.requestedDocumentsNotRead;
        }
    },
    mutations: {
        setDocument: function (state, Documents) {
            state.Documents = Documents;
        },
        setMyDocument: function (state, myDocument) {
            state.myDocument = myDocument;
        },
        setRequestDocument: function (state, requestDocument) {
            state.requestDocument = requestDocument;
        },
        //Visaciones
        setByCheckedDocument: function (state, byCheckDocument) {
            state.byCheckDocument = byCheckDocument;
        },
        setCheckedDocument: function (state, CheckDocument) {
            state.CheckDocument = CheckDocument;
        },
        setNotRelevantDocument: function (state, noRelevantDocument) {
            state.noRelevantDocument = noRelevantDocument;
        },
        //Firma
        setByFirmedDocument: function (state, byFirmDocument) {
            state.byFirmDocument = byFirmDocument;
        },
        setFirmedDocument: function (state, firmDocument) {
            state.firmDocument = firmDocument;
        },
        setRejectDocument: function (state, rejectDocument) {
            state.rejectDocument = rejectDocument;
        },
        //searchDocument
        setSearchDocument: function (state, searchDocument) {
            state.searchDocument = searchDocument;
        },
        setFilterSearchAdvanced: function (state, filterSearchAdvanced) {
            state.filterSearchAdvanced = filterSearchAdvanced;
        },
        //TableFolder
        setFolderDocument: function (state, folderDocument) {
            state.folderDocument = folderDocument;
        },
        //Paginaciones
        setPageMyDocument: function (state, page) {
            state.myDocument.current_page = page;
        },
        setPageRequestDocument: function (state, page) {
            state.requestDocument.current_page = page;
        },
        //Paginacion de las Visaciones
        setPageByCheckedDocument: function (state, page) {
            state.byCheckDocument.current_page = page;
        },
        setPageCheckedDocument: function (state, page) {
            state.CheckDocument.current_page = page;
        },
        setPageNotRelevantDocument: function (state, page) {
            state.noRelevantDocument.current_page = page;
        },
        //Paginacion de las Firmas
        setPageByFirmedDocument: function (state, page) {
            state.byFirmDocument.current_page = page;
        },
        setPageFirmedDocument: function (state, page) {
            state.firmDocument.current_page = page;
        },
        setPageRejectDocument: function (state, page) {
            state.rejectDocument.current_page = page;
        },
        //Paginación de la busqueda avanzada
        setPageSearchDocument: function (state, page) {
            state.searchDocument.current_page = page;
        },
        //Paginación de la Tabla Folder
        setPageFolderDocument: function (state, page) {
            state.folderDocument.current_page = page;
        },
        setLoading: function (state, loading) {
            state.loading = loading;
        },
        setRequestedDocumentsNotRead: function (state, quantity) {
            state.requestedDocumentsNotRead = quantity;
        }
    },
    actions: {
        getDocuments: function (_a) {
            var commit = _a.commit;
            return __awaiter(this, void 0, Promise, function () {
                var document;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            commit('setLoading', true);
                            return [4 /*yield*/, DocumentService.getAll()];
                        case 1:
                            document = _b.sent();
                            commit("setDocument", document);
                            commit('setLoading', false);
                            return [2 /*return*/];
                    }
                });
            });
        },
        getMyDocument: function (_a, data) {
            var commit = _a.commit, state = _a.state;
            return __awaiter(this, void 0, Promise, function () {
                var profileLoggedIn, documents, user, user_id;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            commit('setLoading', true);
                            profileLoggedIn = store.getters['userSession/getProfile'];
                            if (!(profileLoggedIn == "opartes")) return [3 /*break*/, 2];
                            return [4 /*yield*/, DocumentService.getByUserLocationId(__assign(__assign({}, data), { location_id: store.getters['userSession/getCurrentLocation'].location_id }), state.myDocument.current_page)];
                        case 1:
                            documents = _b.sent();
                            return [3 /*break*/, 4];
                        case 2:
                            user = window.localStorage.getItem("user");
                            user_id = 0;
                            if (user) {
                                user_id = JSON.parse(user).id;
                            }
                            return [4 /*yield*/, DocumentService.getUserId(__assign(__assign({}, data), { id: user_id }), state.myDocument.current_page)];
                        case 3:
                            documents = _b.sent();
                            _b.label = 4;
                        case 4:
                            commit("setMyDocument", documents);
                            commit('setLoading', false);
                            return [2 /*return*/];
                    }
                });
            });
        },
        getRequestDocument: function (_a, data) {
            var commit = _a.commit, state = _a.state, dispatch = _a.dispatch;
            return __awaiter(this, void 0, Promise, function () {
                var profileLoggedIn, documents, user, user_id;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            commit('setLoading', true);
                            profileLoggedIn = store.getters['userSession/getProfile'];
                            if (!(profileLoggedIn == "opartes")) return [3 /*break*/, 2];
                            return [4 /*yield*/, DocumentService.getRequestedDocumentsByLocationId(__assign(__assign({}, data), { location_id: store.getters['userSession/getCurrentLocation'].location_id }), state.requestDocument.current_page)];
                        case 1:
                            documents = _b.sent();
                            return [3 /*break*/, 4];
                        case 2:
                            user = window.localStorage.getItem("user");
                            user_id = 0;
                            if (user) {
                                user_id = JSON.parse(user).id;
                            }
                            return [4 /*yield*/, DocumentService.getRequestId(__assign(__assign({}, data), { user_id: user_id }), state.requestDocument.current_page)];
                        case 3:
                            documents = _b.sent();
                            _b.label = 4;
                        case 4:
                            dispatch('getRequestedDocumentsNotRead');
                            commit("setRequestDocument", documents);
                            commit('setLoading', false);
                            return [2 /*return*/];
                    }
                });
            });
        },
        //Visaciones    
        getByCheckedDocument: function (_a, id) {
            var commit = _a.commit, state = _a.state;
            return __awaiter(this, void 0, Promise, function () {
                var document;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            commit('setLoading', true);
                            return [4 /*yield*/, DocumentService.getByCheckedState(id, 'por visar', state.byCheckDocument.current_page)];
                        case 1:
                            document = _b.sent();
                            commit("setByCheckedDocument", document);
                            commit('setLoading', false);
                            return [2 /*return*/];
                    }
                });
            });
        },
        getCheckedDocument: function (_a, id) {
            var commit = _a.commit, state = _a.state;
            return __awaiter(this, void 0, Promise, function () {
                var document;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            commit('setLoading', true);
                            return [4 /*yield*/, DocumentService.getByCheckedState(id, 'visado', state.CheckDocument.current_page)];
                        case 1:
                            document = _b.sent();
                            commit("setCheckedDocument", document);
                            commit('setLoading', false);
                            return [2 /*return*/];
                    }
                });
            });
        },
        getNotRelevantDocument: function (_a, id) {
            var commit = _a.commit, state = _a.state;
            return __awaiter(this, void 0, Promise, function () {
                var document;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            commit('setLoading', true);
                            return [4 /*yield*/, DocumentService.getByCheckedState(id, 'no pertinente', state.noRelevantDocument.current_page)];
                        case 1:
                            document = _b.sent();
                            commit("setNotRelevantDocument", document);
                            commit('setLoading', false);
                            return [2 /*return*/];
                    }
                });
            });
        },
        //Firma
        getByFirmedDocument: function (_a, id) {
            var commit = _a.commit, state = _a.state;
            return __awaiter(this, void 0, Promise, function () {
                var document;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            commit('setLoading', true);
                            return [4 /*yield*/, DocumentService.getByFirmedState(id, 'por firmar', state.byFirmDocument.current_page)];
                        case 1:
                            document = _b.sent();
                            commit("setByFirmedDocument", document);
                            commit('setLoading', false);
                            return [2 /*return*/];
                    }
                });
            });
        },
        getFirmedDocument: function (_a, id) {
            var commit = _a.commit, state = _a.state;
            return __awaiter(this, void 0, Promise, function () {
                var document;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            commit('setLoading', true);
                            return [4 /*yield*/, DocumentService.getByFirmedState(id, 'firmado', state.firmDocument.current_page)];
                        case 1:
                            document = _b.sent();
                            commit("setFirmedDocument", document);
                            commit('setLoading', false);
                            return [2 /*return*/];
                    }
                });
            });
        },
        getRejectDocument: function (_a, id) {
            var commit = _a.commit, state = _a.state;
            return __awaiter(this, void 0, Promise, function () {
                var document;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            commit('setLoading', true);
                            return [4 /*yield*/, DocumentService.getByFirmedState(id, 'rechazado', state.rejectDocument.current_page)];
                        case 1:
                            document = _b.sent();
                            commit("setRejectDocument", document);
                            commit('setLoading', false);
                            return [2 /*return*/];
                    }
                });
            });
        },
        //Busqueda Avanzada
        getSearchDocument: function (_a) {
            var commit = _a.commit, state = _a.state;
            return __awaiter(this, void 0, Promise, function () {
                var profileLoggedIn, documents, FILTER, location_id, user, user_id;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            commit('setLoading', true);
                            profileLoggedIn = store.getters['userSession/getProfile'];
                            FILTER = state.filterSearchAdvanced;
                            if (!(profileLoggedIn == "opartes")) return [3 /*break*/, 2];
                            location_id = store.getters['userSession/getCurrentLocation'].location_id;
                            return [4 /*yield*/, DocumentService.getByAllDocumentLocation(location_id, FILTER, state.searchDocument.current_page)];
                        case 1:
                            documents = _b.sent();
                            return [3 /*break*/, 4];
                        case 2:
                            user = window.localStorage.getItem("user");
                            user_id = 0;
                            if (user) {
                                user_id = JSON.parse(user).id;
                            }
                            return [4 /*yield*/, DocumentService.getByAllDocument(user_id, FILTER, state.searchDocument.current_page)];
                        case 3:
                            documents = _b.sent();
                            _b.label = 4;
                        case 4:
                            commit("setSearchDocument", documents);
                            commit('setLoading', false);
                            return [2 /*return*/];
                    }
                });
            });
        },
        //Table Folder
        getFolderDocument: function (_a, payLoad) {
            var commit = _a.commit, state = _a.state;
            return __awaiter(this, void 0, Promise, function () {
                var document;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            commit('setLoading', true);
                            return [4 /*yield*/, DocumentService.getByFolderId(payLoad, state.folderDocument.current_page)];
                        case 1:
                            document = _b.sent();
                            commit("setFolderDocument", document);
                            commit('setLoading', false);
                            return [2 /*return*/];
                    }
                });
            });
        },
        //Carga el número de documentos solicitados no leidos
        getRequestedDocumentsNotRead: function (_a) {
            var commit = _a.commit, state = _a.state;
            return __awaiter(this, void 0, Promise, function () {
                var profileLoggedIn, documents, user, user_id;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            profileLoggedIn = store.getters['userSession/getProfile'];
                            if (!(profileLoggedIn == "opartes")) return [3 /*break*/, 2];
                            return [4 /*yield*/, DocumentService.getRequestedDocumentsByLocationId({
                                    location_id: store.getters['userSession/getCurrentLocation'].location_id,
                                    state_read: "no leido"
                                }, state.requestDocument.current_page)];
                        case 1:
                            documents = _b.sent();
                            return [3 /*break*/, 4];
                        case 2:
                            user = window.localStorage.getItem("user");
                            user_id = 0;
                            if (user) {
                                user_id = JSON.parse(user).id;
                            }
                            return [4 /*yield*/, DocumentService.getRequestId({
                                    id: user_id,
                                    state_read: "no leido"
                                }, state.requestDocument.current_page)];
                        case 3:
                            documents = _b.sent();
                            _b.label = 4;
                        case 4:
                            commit("setRequestedDocumentsNotRead", documents.data.length);
                            return [2 /*return*/];
                    }
                });
            });
        }
    }
};
export default moduleDocument;
