import { __assign, __awaiter, __generator } from "tslib";
import Vue from "vue";
import { DocumentTypeService } from "@/services/models/document/document_type";
import { DocumentService } from "@/services/models/document/document";
import { mapActions } from "vuex";
import { RemitentService } from "@/services/models/document/remitent";
export default Vue.extend({
    props: {
        documentProp: {
            required: false,
            type: Object,
        },
    },
    data: function () {
        return {
            dialog: false,
            items: [],
            listRemitents: [],
            isLoading: false,
            document: {},
        };
    },
    methods: __assign(__assign({}, mapActions({
        setDocument: "tabsDocument/getLoadTable",
    })), { clearData: function () {
            this.cleanForm(this, "formCreate");
        },
        loadData: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0:
                            this.isLoading = true;
                            _a = this;
                            return [4 /*yield*/, DocumentTypeService.getAll()];
                        case 1:
                            _a.items = _d.sent();
                            _b = this;
                            return [4 /*yield*/, RemitentService.getAll()];
                        case 2:
                            _b.listRemitents = _d.sent();
                            _c = this;
                            return [4 /*yield*/, DocumentService.getById(this.documentProp.id)];
                        case 3:
                            _c.document = _d.sent();
                            this.isLoading = false;
                            return [2 /*return*/];
                    }
                });
            });
        },
        saveDocument: function () {
            var _a;
            return __awaiter(this, void 0, void 0, function () {
                var data, response, error_1;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            if (!this.$refs.formCreate.validate()) {
                                this.displayToast({
                                    type: "error",
                                    message: "Favor de llenar todos los campos requeridos",
                                });
                                return [2 /*return*/];
                            }
                            _b.label = 1;
                        case 1:
                            _b.trys.push([1, 3, 4, 5]);
                            this.isLoading = true;
                            data = __assign(__assign({}, this.document), { user_id: this.getCurrentUserLoggedIn().id, location_id: ((_a = this.getCurrentUserLoggedIn().users_locations) === null || _a === void 0 ? void 0 : _a[0].location_id) || 0 });
                            return [4 /*yield*/, DocumentService.update(this.documentProp.id, data)];
                        case 2:
                            response = _b.sent();
                            console.log(response);
                            this.setDocument(__assign({ user_id: this.getCurrentUserLoggedIn().id, folder_id: this.$route.params.id }, (this.getProfile() === "opartes" && {
                                location_id: this.getUserLocation().location_id,
                            })));
                            this.dialog = false;
                            return [3 /*break*/, 5];
                        case 3:
                            error_1 = _b.sent();
                            console.log(error_1);
                            return [3 /*break*/, 5];
                        case 4:
                            this.isLoading = false;
                            return [7 /*endfinally*/];
                        case 5: return [2 /*return*/];
                    }
                });
            });
        } }),
    watch: {
        dialog: function () {
            if (this.dialog) {
                this.loadData();
            }
        },
    },
});
