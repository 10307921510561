import { __awaiter, __generator } from "tslib";
import { ModuleService } from "@/services/models/user/module";
import Vue from "vue";
import { UserModulePermissionService } from "@/services/models/user/userModulePermission";
import { UserModuleService } from "@/services/models/user/userModule";
import { PermissionService } from "@/services/models/user/permission";
export default Vue.extend({
    name: "UserModules",
    props: {
        userId: {
            type: Number,
            required: true,
        },
    },
    data: function () {
        return {
            modules: [],
            loadingModule: false,
            usersModules: [],
            usersModulesOriginal: [],
            module_id: 0,
            headers: [
                {
                    text: "Módulo",
                    sortable: false,
                    value: "module.name",
                },
                {
                    text: "Permisos",
                    sortable: false,
                    value: "permission",
                },
                {
                    text: "Eliminar",
                    sortable: false,
                    value: "delete",
                },
            ],
            loading: false,
            userModulesLoaded: false,
            loadingModal: false
        };
    },
    mounted: function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.loadingModule = true;
                        _a = this;
                        return [4 /*yield*/, ModuleService.getAll()];
                    case 1:
                        _a.modules = _b.sent();
                        return [4 /*yield*/, this.getUserModules()];
                    case 2:
                        _b.sent();
                        this.loadingModule = false;
                        return [2 /*return*/];
                }
            });
        });
    },
    methods: {
        changePermission: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.loadingModal = true;
                            return [4 /*yield*/, this.addPermissions()];
                        case 1:
                            _a.sent();
                            return [4 /*yield*/, this.deletePermissions()];
                        case 2:
                            _a.sent();
                            return [4 /*yield*/, this.getUserModules()];
                        case 3:
                            _a.sent();
                            this.loadingModal = false;
                            return [2 /*return*/];
                    }
                });
            });
        },
        addPermissions: function () {
            return __awaiter(this, void 0, void 0, function () {
                var i, j, userModulePermission, isPermissionAdded;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            i = 0;
                            _a.label = 1;
                        case 1:
                            if (!(i < this.usersModules.length)) return [3 /*break*/, 6];
                            j = 0;
                            _a.label = 2;
                        case 2:
                            if (!(j < this.usersModules[i].permissions.length)) return [3 /*break*/, 5];
                            userModulePermission = this.usersModules[i].permissions[j];
                            isPermissionAdded = this.usersModules[i].users_modules_permissions
                                .map(function (val) { return val.permission_id; })
                                .includes(userModulePermission.id);
                            console.log(isPermissionAdded);
                            if (!!isPermissionAdded) return [3 /*break*/, 4];
                            return [4 /*yield*/, UserModulePermissionService.create({
                                    id: 0,
                                    user_module_id: this.usersModules[i].id,
                                    permission_id: userModulePermission.id
                                })];
                        case 3:
                            _a.sent();
                            _a.label = 4;
                        case 4:
                            j++;
                            return [3 /*break*/, 2];
                        case 5:
                            i++;
                            return [3 /*break*/, 1];
                        case 6: return [2 /*return*/];
                    }
                });
            });
        },
        deletePermissions: function () {
            return __awaiter(this, void 0, void 0, function () {
                var i, j, userModulePermission, isPermissionAdded;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            i = 0;
                            _a.label = 1;
                        case 1:
                            if (!(i < this.usersModules.length)) return [3 /*break*/, 6];
                            j = 0;
                            _a.label = 2;
                        case 2:
                            if (!(j < this.usersModules[i].users_modules_permissions.length)) return [3 /*break*/, 5];
                            userModulePermission = this.usersModules[i].users_modules_permissions[j];
                            isPermissionAdded = this.usersModules[i].permissions
                                .map(function (val) { return val.id; })
                                .includes(userModulePermission.permission_id);
                            if (!!isPermissionAdded) return [3 /*break*/, 4];
                            console.log(userModulePermission);
                            return [4 /*yield*/, UserModulePermissionService.remove(userModulePermission.id)];
                        case 3:
                            _a.sent();
                            _a.label = 4;
                        case 4:
                            j++;
                            return [3 /*break*/, 2];
                        case 5:
                            i++;
                            return [3 /*break*/, 1];
                        case 6: return [2 /*return*/];
                    }
                });
            });
        },
        getUserModules: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a, _loop_1, this_1, i;
                var _this = this;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            this.userModulesLoaded = false;
                            _a = this;
                            return [4 /*yield*/, UserModuleService.getByUserId(this.userId)];
                        case 1:
                            _a.usersModules = _b.sent();
                            _loop_1 = function (i) {
                                var userModule, permissions;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            userModule = this_1.usersModules[i];
                                            return [4 /*yield*/, PermissionService.getByModuleId(userModule.module_id)];
                                        case 1:
                                            permissions = _a.sent();
                                            if (this_1.usersModules[i].module) {
                                                this_1.usersModules[i].module.permissionsList = permissions;
                                                this_1.usersModules[i].permissions = this_1.usersModules[i].module.permissionsList.filter(function (value) {
                                                    return _this.usersModules[i].users_modules_permissions
                                                        .map(function (val) { return val.permission_id; })
                                                        .includes(value.id);
                                                });
                                            }
                                            return [2 /*return*/];
                                    }
                                });
                            };
                            this_1 = this;
                            i = 0;
                            _b.label = 2;
                        case 2:
                            if (!(i < this.usersModules.length)) return [3 /*break*/, 5];
                            return [5 /*yield**/, _loop_1(i)];
                        case 3:
                            _b.sent();
                            _b.label = 4;
                        case 4:
                            i++;
                            return [3 /*break*/, 2];
                        case 5:
                            this.usersModulesOriginal = this.usersModules;
                            this.userModulesLoaded = true;
                            return [2 /*return*/];
                    }
                });
            });
        },
        deleteModule: function (userModule) {
            return __awaiter(this, void 0, void 0, function () {
                var i, userModulePermission;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.loadingModal = true;
                            if (!userModule.users_modules_permissions) return [3 /*break*/, 4];
                            i = 0;
                            _a.label = 1;
                        case 1:
                            if (!(i < userModule.users_modules_permissions.length)) return [3 /*break*/, 4];
                            userModulePermission = userModule.users_modules_permissions[i];
                            return [4 /*yield*/, UserModulePermissionService.remove(userModulePermission.id)];
                        case 2:
                            _a.sent();
                            _a.label = 3;
                        case 3:
                            i++;
                            return [3 /*break*/, 1];
                        case 4: return [4 /*yield*/, UserModuleService.remove(userModule.id)];
                        case 5:
                            _a.sent();
                            this.getUserModules();
                            this.loadingModal = false;
                            return [2 /*return*/];
                    }
                });
            });
        },
        addModule: function () {
            var _this = this;
            if (!this.module_id) {
                this.displayToast({
                    type: "warning",
                    message: "Por favor seleccionar un módulo",
                });
                return;
            }
            var isModuleAdded = this.usersModules.find(function (userModule) { return userModule.module_id == _this.module_id; });
            if (isModuleAdded) {
                this.displayToast({
                    type: "warning",
                    message: "El usuario ya tiene asociado este módulo",
                });
                return;
            }
            this.storeModule();
        },
        storeModule: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.loadingModal = true;
                            return [4 /*yield*/, UserModuleService.create({
                                    id: 0,
                                    name: "test",
                                    module_id: this.module_id,
                                    user_id: this.userId,
                                })];
                        case 1:
                            _a.sent();
                            this.getUserModules();
                            this.loadingModal = false;
                            return [2 /*return*/];
                    }
                });
            });
        },
    },
});
