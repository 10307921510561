import { __assign, __awaiter, __generator } from "tslib";
import { DocumentDetailCheckedService } from "@/services/models/document/documentDetailChecked";
import { DocumentStateService } from "@/services/models/document/documentState";
import { HistoryService } from "@/services/models/document/history";
import { UserService } from "@/services/models/user/user";
import Vue from "vue";
import { mapActions } from "vuex";
export default Vue.extend({
    props: {
        detailProp: {
            type: Object,
        },
        documentProp: {
            type: Object,
        },
    },
    data: function () {
        return {
            dialog: false,
            isLoading: false,
            userLocationSelected: {},
            userLocations: [],
        };
    },
    mounted: function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.loadUserLocations();
                return [2 /*return*/];
            });
        });
    },
    methods: __assign(__assign({}, mapActions({
        getHistories: "history/getDocumentDetailId",
        getByCheckedDocument: "documents/getByCheckedDocument",
        getCheckedDocument: "documents/getCheckedDocument",
        getNotRelevantDocument: "documents/getNotRelevantDocument",
        getDocumentsDetail: "documentDetail/getByDocumentId",
    })), { saveCheck: function (statesDocuments) {
            return __awaiter(this, void 0, void 0, function () {
                var error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!this.userLocationSelected.id) {
                                this.displayToast({
                                    type: 'warning',
                                    message: 'Por favor seleccionar un perfil'
                                });
                                return [2 /*return*/];
                            }
                            this.isLoading = true;
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 5, 6, 7]);
                            return [4 /*yield*/, this.postHistory(statesDocuments)];
                        case 2:
                            _a.sent();
                            return [4 /*yield*/, this.postState(statesDocuments)];
                        case 3:
                            _a.sent();
                            return [4 /*yield*/, this.putChecked(statesDocuments)];
                        case 4:
                            _a.sent();
                            this.getHistories(this.detailProp.id);
                            this.getByCheckedDocument(this.getCurrentUserLoggedIn().id);
                            this.getCheckedDocument(this.getCurrentUserLoggedIn().id);
                            this.getNotRelevantDocument(this.getCurrentUserLoggedIn().id);
                            this.displayToast({
                                type: "success",
                                message: "Se ha visado el documento con éxito",
                            });
                            this.getDocumentsDetail(this.detailProp.document_id);
                            this.dialog = false;
                            return [3 /*break*/, 7];
                        case 5:
                            error_1 = _a.sent();
                            console.log(error_1);
                            throw error_1;
                        case 6:
                            this.isLoading = false;
                            return [7 /*endfinally*/];
                        case 7: return [2 /*return*/];
                    }
                });
            });
        },
        postHistory: function (state) {
            return __awaiter(this, void 0, Promise, function () {
                var data, error_2;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            data = {
                                document_detail_id: this.detailProp.id,
                                user_origin_id: this.getCurrentUserLoggedIn().id,
                                user_destination_id: this.getCurrentUserLoggedIn().id || 0,
                                location_origin_id: this.getUserLocation().location_id,
                                location_destination_id: 1,
                                state: state,
                            };
                            return [4 /*yield*/, HistoryService.create(data)];
                        case 1:
                            _a.sent();
                            return [3 /*break*/, 3];
                        case 2:
                            error_2 = _a.sent();
                            console.log(error_2);
                            throw error_2;
                        case 3: return [2 /*return*/];
                    }
                });
            });
        },
        postState: function (state) {
            return __awaiter(this, void 0, void 0, function () {
                var dataStates, data, error_3;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            dataStates = this.documentProp.documents_states[0];
                            data = __assign(__assign({}, dataStates), { state: state });
                            return [4 /*yield*/, DocumentStateService.update(data.id || 0, data)];
                        case 1:
                            _a.sent();
                            return [3 /*break*/, 3];
                        case 2:
                            error_3 = _a.sent();
                            console.log(error_3);
                            throw error_3;
                        case 3: return [2 /*return*/];
                    }
                });
            });
        },
        putChecked: function (state) {
            return __awaiter(this, void 0, void 0, function () {
                var listCheckeds, data, error_4;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 3, , 4]);
                            return [4 /*yield*/, DocumentDetailCheckedService.getByDocumentDetailId(this.detailProp.id)];
                        case 1:
                            listCheckeds = _a.sent();
                            listCheckeds = listCheckeds.filter(function (data) {
                                return data.user_id == _this.getCurrentUserLoggedIn().id;
                            });
                            data = __assign(__assign({}, listCheckeds[0]), { state: state, user_location_id: this.userLocationSelected.id });
                            return [4 /*yield*/, DocumentDetailCheckedService.update(listCheckeds[0].id || 0, data)];
                        case 2:
                            _a.sent();
                            return [3 /*break*/, 4];
                        case 3:
                            error_4 = _a.sent();
                            console.log(error_4);
                            throw error_4;
                        case 4: return [2 /*return*/];
                    }
                });
            });
        },
        loadUserLocations: function () {
            return __awaiter(this, void 0, void 0, function () {
                var user, locations;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, UserService.getById(this.getCurrentUserLoggedIn().id)];
                        case 1:
                            user = _a.sent();
                            locations = [];
                            if (user.users_locations) {
                                locations = user.users_locations.map(function (location) { return (__assign(__assign({}, location), { completeName: (location.position ? location.position.name : "") + " " + (location.location ? location.location.name : "") })); });
                            }
                            this.userLocations = locations;
                            return [2 /*return*/];
                    }
                });
            });
        } }),
});
