import { __assign } from "tslib";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import ButtonCreateFolder from "../document/folder/ButtonCreateFolder.vue";
export default Vue.extend({
    components: { ButtonCreateFolder: ButtonCreateFolder },
    props: {
        profile: {
            type: String,
            required: true,
        },
    },
    data: function () {
        return {
            folderId: 0,
            isOpen: false,
            dialog: false
        };
    },
    mounted: function () {
        this.getFolders(this.getCurrentUserLoggedIn().id);
        if (this.$route.params.id) {
            this.folderId = Number(this.$route.params.id);
            this.isOpen = true;
        }
    },
    computed: __assign({}, mapGetters({
        folders: "folders/getFolder",
    })),
    watch: {
        '$route.params.id': function () {
            this.folderId = Number(this.$route.params.id);
        }
    },
    methods: __assign(__assign({}, mapActions({
        getFolders: "folders/getFolder",
    })), { selectFolder: function (child) {
            var data = {
                name: "folder" + this.profile,
                params: __assign({ id: child.id.toString(), userID: child.user_id.toString(), folderName: child.name }, (child.description && { folderDescription: child.description })),
            };
            this.$router.push(data);
            this.$store.commit("tabsDocument/setTabNumber", "tab-folder");
        } }),
});
