import { __assign, __awaiter, __generator, __spreadArrays } from "tslib";
import Vue from "vue";
import { DocumentStateService } from "@/services/models/document/documentState";
import { HistoryService } from "@/services/models/document/history";
import { DocumentDetailFirmedService } from "@/services/models/document/documentDetailFirmed";
import { DocumentDetailCheckedService } from "@/services/models/document/documentDetailChecked";
import { DocumentStateLocationService } from "@/services/models/document/documentStateLocation";
import InputSelectUsers from "./InputSelectUsers.vue";
import { mapActions, mapMutations } from "vuex";
import { StablishmentService } from "@/services/models/stablishment";
export default Vue.extend({
    name: "ButtonSend",
    components: { InputSelectUsers: InputSelectUsers },
    props: {
        detailProp: {
            type: Object,
        },
    },
    data: function () {
        return {
            isLoading: false,
            description: "",
            dialog: false,
            stablishments: [],
            loadingStablishments: false,
            stablishment_id: null
        };
    },
    mounted: function () {
        this.loadStablishments();
    },
    methods: __assign(__assign(__assign({}, mapMutations({
        setStablishmentId: "users/setStablishmentIdSelected",
    })), mapActions({
        getHistories: "history/getDocumentDetailId",
        loadTable: "tabsDocument/getLoadTable",
    })), { saveSend: function () {
            return __awaiter(this, void 0, void 0, function () {
                var functionaries, checkeds, firms, promise, _loop_1, i, _loop_2, i, _loop_3, i, resp, data, data, respLocation, dataLocation, dataLocation;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.isLoading = true;
                            functionaries = this.$refs.selectFunctionary.usersSelects;
                            checkeds = this.$refs.selectCheckeds.usersSelects;
                            firms = this.$refs.selectFirms.usersSelects;
                            if (functionaries.length == 0 && checkeds.length == 0 && firms.length == 0) {
                                this.displayToast({
                                    type: "error",
                                    message: "Favor de seleccionar a algún funcionario",
                                });
                                this.isLoading = false;
                                return [2 /*return*/];
                            }
                            promise = [];
                            return [4 /*yield*/, this.comprobateBeforeSend(functionaries, false)];
                        case 1:
                            if (!(_a.sent())) {
                                this.isLoading = false;
                                return [2 /*return*/];
                            }
                            _loop_1 = function (i) {
                                promise.push(new Promise(function (resolve, reject) {
                                    try {
                                        _this.postHistory(functionaries[i]).then(function (resp) {
                                            var response = resp;
                                            _this.postState(response, functionaries[i], "recibido").then(function () {
                                                _this.postStateLocation(response, functionaries[i], "recibido").then(function (response) {
                                                    resolve(response);
                                                });
                                            });
                                        });
                                    }
                                    catch (error) {
                                        console.log(error);
                                        throw reject(error);
                                    }
                                }));
                            };
                            for (i = 0; i < functionaries.length; i++) {
                                _loop_1(i);
                            }
                            return [4 /*yield*/, this.comprobateBeforeSend(checkeds, false)];
                        case 2:
                            if (!(_a.sent())) {
                                this.isLoading = false;
                                return [2 /*return*/];
                            }
                            _loop_2 = function (i) {
                                promise.push(new Promise(function (resolve, reject) {
                                    try {
                                        _this.postHistory(checkeds[i]).then(function (response) { return __awaiter(_this, void 0, void 0, function () {
                                            return __generator(this, function (_a) {
                                                switch (_a.label) {
                                                    case 0: return [4 /*yield*/, this.postState(response, checkeds[i], "por visar")];
                                                    case 1:
                                                        _a.sent();
                                                        return [4 /*yield*/, this.postChecked(checkeds[i].id, response === null || response === void 0 ? void 0 : response.id)];
                                                    case 2:
                                                        _a.sent();
                                                        resolve(response);
                                                        return [2 /*return*/];
                                                }
                                            });
                                        }); });
                                    }
                                    catch (error) {
                                        console.log(error);
                                        throw reject(error);
                                    }
                                }));
                            };
                            for (i = 0; i < checkeds.length; i++) {
                                _loop_2(i);
                            }
                            return [4 /*yield*/, this.comprobateBeforeSend(firms, true)];
                        case 3:
                            if (!(_a.sent())) {
                                this.isLoading = false;
                                return [2 /*return*/];
                            }
                            _loop_3 = function (i) {
                                promise.push(new Promise(function (resolve, reject) {
                                    try {
                                        _this.postHistory(firms[i]).then(function (response) { return __awaiter(_this, void 0, void 0, function () {
                                            return __generator(this, function (_a) {
                                                switch (_a.label) {
                                                    case 0: return [4 /*yield*/, this.postState(response, firms[i], "por firmar")];
                                                    case 1:
                                                        _a.sent();
                                                        return [4 /*yield*/, this.postFirmed(firms[i].id, response === null || response === void 0 ? void 0 : response.id)];
                                                    case 2:
                                                        _a.sent();
                                                        resolve(response);
                                                        return [2 /*return*/];
                                                }
                                            });
                                        }); });
                                    }
                                    catch (error) {
                                        console.log(error);
                                        throw reject(error);
                                    }
                                }));
                            };
                            for (i = 0; i < firms.length; i++) {
                                _loop_3(i);
                            }
                            return [4 /*yield*/, DocumentStateService.getByDocumentIdUserId(this.detailProp.document_id, this.getCurrentUserLoggedIn().id)];
                        case 4:
                            resp = _a.sent();
                            if (!(resp.length == 0)) return [3 /*break*/, 6];
                            data = {
                                id: 0,
                                document_id: this.detailProp.document_id,
                                state: "enviado",
                                state_read: "leido",
                                user_id: this.getCurrentUserLoggedIn().id,
                            };
                            return [4 /*yield*/, DocumentStateService.create(data)];
                        case 5:
                            _a.sent();
                            return [3 /*break*/, 8];
                        case 6:
                            data = __assign(__assign({}, resp[0]), { state: "enviado", state_read: "leido" });
                            return [4 /*yield*/, DocumentStateService.update(resp[0].id || 0, data)];
                        case 7:
                            _a.sent();
                            _a.label = 8;
                        case 8: return [4 /*yield*/, DocumentStateLocationService.getByDocumentIdLocationId({
                                document_id: this.detailProp.document_id,
                                location_id: this.getUserLocation().location_id,
                            })];
                        case 9:
                            respLocation = _a.sent();
                            if (!(respLocation.length == 0)) return [3 /*break*/, 11];
                            dataLocation = {
                                id: 0,
                                document_id: this.detailProp.document_id,
                                state: "enviado",
                                state_read: "leido",
                                location_id: this.getUserLocation().location_id,
                            };
                            return [4 /*yield*/, DocumentStateLocationService.create(dataLocation)];
                        case 10:
                            _a.sent();
                            return [3 /*break*/, 13];
                        case 11:
                            dataLocation = __assign(__assign({}, respLocation[0]), { state: "enviado" });
                            return [4 /*yield*/, DocumentStateLocationService.update(respLocation[0].id || 0, dataLocation)];
                        case 12:
                            _a.sent();
                            _a.label = 13;
                        case 13:
                            //************************************************************/
                            Promise.all(promise)
                                .then(function () {
                                _this.getHistories(_this.detailProp.id);
                                var dataRefresh = {
                                    user_id: _this.getCurrentUserLoggedIn().id,
                                    folder_id: _this.$route.params.id,
                                };
                                _this.loadTable(dataRefresh);
                                _this.isLoading = false;
                                _this.dialog = false;
                            })
                                .catch(function (reason) {
                                console.log(reason);
                            });
                            return [2 /*return*/];
                    }
                });
            });
        },
        postHistory: function (user) {
            return __awaiter(this, void 0, Promise, function () {
                var data;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            try {
                                data = {
                                    document_detail_id: this.detailProp.id,
                                    user_origin_id: this.getCurrentUserLoggedIn().id,
                                    user_destination_id: user.id || 0,
                                    location_origin_id: this.getUserLocation().location_id,
                                    location_destination_id: 1,
                                    description: this.description,
                                    state: "enviado",
                                };
                            }
                            catch (error) {
                                console.log(error);
                                throw error;
                            }
                            return [4 /*yield*/, HistoryService.create(data)];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        },
        postState: function (history, user, state) {
            return __awaiter(this, void 0, void 0, function () {
                var response, data, error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 5, , 6]);
                            return [4 /*yield*/, DocumentStateService.getByDocumentIdUserId(this.detailProp.document_id, user.id)];
                        case 1:
                            response = _a.sent();
                            if (!(response.length != 0)) return [3 /*break*/, 3];
                            return [4 /*yield*/, DocumentStateService.update(response[0].id || 0, __assign(__assign({}, response[0]), { state: state }))];
                        case 2:
                            _a.sent();
                            return [2 /*return*/];
                        case 3:
                            data = {
                                id: 0,
                                user_id: user.id,
                                history_id: history.id,
                                document_id: this.detailProp.document_id,
                                state: state,
                                state_read: "no leido",
                            };
                            return [4 /*yield*/, DocumentStateService.create(data)];
                        case 4:
                            _a.sent();
                            return [3 /*break*/, 6];
                        case 5:
                            error_1 = _a.sent();
                            console.log(error_1);
                            throw error_1;
                        case 6: return [2 /*return*/];
                    }
                });
            });
        },
        postFirmed: function (user_id, history_id) {
            return __awaiter(this, void 0, void 0, function () {
                var data, error_2;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            data = {
                                document_detail_id: this.detailProp.id,
                                state: "por firmar",
                                date_firmed: null,
                                user_id: user_id,
                                history_id: history_id,
                                id: 0,
                            };
                            return [4 /*yield*/, DocumentDetailFirmedService.create(data)];
                        case 1:
                            _a.sent();
                            return [3 /*break*/, 3];
                        case 2:
                            error_2 = _a.sent();
                            console.log(error_2);
                            throw error_2;
                        case 3: return [2 /*return*/];
                    }
                });
            });
        },
        postChecked: function (user_id, history_id) {
            return __awaiter(this, void 0, void 0, function () {
                var data, error_3;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            data = {
                                document_detail_id: this.detailProp.id,
                                state: "por visar",
                                date_checked: null,
                                user_id: user_id,
                                history_id: history_id,
                            };
                            return [4 /*yield*/, DocumentDetailCheckedService.create(data)];
                        case 1:
                            _a.sent();
                            return [3 /*break*/, 3];
                        case 2:
                            error_3 = _a.sent();
                            console.log(error_3);
                            throw error_3;
                        case 3: return [2 /*return*/];
                    }
                });
            });
        },
        comprobateBeforeSend: function (users, toFirm) {
            return __awaiter(this, void 0, void 0, function () {
                var verifiySend, usersFirms, usersCheckeds, verifyRepeatUsers_1, verifyCheckState_1, verifyAllCheckedReady_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            verifiySend = true;
                            if (!(users.length != 0)) return [3 /*break*/, 3];
                            return [4 /*yield*/, DocumentDetailFirmedService.getByDocumentDetailId(this.detailProp.id)];
                        case 1:
                            usersFirms = _a.sent();
                            return [4 /*yield*/, DocumentDetailCheckedService.getByDocumentDetailId(this.detailProp.id)];
                        case 2:
                            usersCheckeds = _a.sent();
                            verifyRepeatUsers_1 = true;
                            usersFirms.forEach(function (signatory) {
                                users.forEach(function (user) {
                                    if (signatory.user_id == user.id) {
                                        verifyRepeatUsers_1 = false;
                                    }
                                });
                            });
                            usersCheckeds.forEach(function (checked) {
                                users.forEach(function (user) {
                                    if (checked.user_id == user.id) {
                                        verifyRepeatUsers_1 = false;
                                    }
                                });
                            });
                            if (verifyRepeatUsers_1) {
                                verifyCheckState_1 = true;
                                usersCheckeds.forEach(function (checked) {
                                    users.forEach(function (user) {
                                        if (checked.state == "por visar" && checked.user_id == user.id) {
                                            verifyCheckState_1 = false;
                                        }
                                    });
                                });
                                if (!verifyCheckState_1) {
                                    //Faltan visaciones
                                    verifiySend = false;
                                    this.displayToast({
                                        type: "error",
                                        message: "No se puede enviar para firmar, faltan visaciones",
                                    });
                                }
                                else {
                                    if (toFirm) {
                                        if (this.detailProp.documents_details_firmed.length != 0) {
                                            if (this.detailProp.documents_details_firmed[0].state == 'firmado') {
                                                verifiySend = false;
                                                this.displayToast({
                                                    type: "error",
                                                    message: "No se puede enviar para firmar, documento ya se encuentra firmado",
                                                });
                                            }
                                            else {
                                                verifiySend = false;
                                                this.displayToast({
                                                    type: "error",
                                                    message: "No se puede enviar para firmar, documento ya tiene un firmante asociado",
                                                });
                                            }
                                        }
                                        verifyAllCheckedReady_1 = true;
                                        usersCheckeds.forEach(function (checked) {
                                            if (checked.state == "por visar") {
                                                verifyAllCheckedReady_1 = false;
                                            }
                                        });
                                        if (!verifyAllCheckedReady_1) {
                                            //Faltan visaciones
                                            verifiySend = false;
                                            this.displayToast({
                                                type: "error",
                                                message: "No se puede enviar para firmar, faltan visaciones",
                                            });
                                        }
                                    }
                                }
                            }
                            else {
                                verifiySend = false;
                                this.displayToast({
                                    type: "error",
                                    message: "Existen usuario que ya estan como firmantes/visadores",
                                });
                            }
                            _a.label = 3;
                        case 3: return [2 /*return*/, verifiySend];
                    }
                });
            });
        },
        postStateLocation: function (history, user, state) {
            var _a, _b;
            return __awaiter(this, void 0, void 0, function () {
                var response, data, error_4;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            _c.trys.push([0, 5, , 6]);
                            return [4 /*yield*/, DocumentStateLocationService.getByDocumentIdLocationId({
                                    document_id: this.detailProp.document_id,
                                    location_id: ((_a = user.users_locations) === null || _a === void 0 ? void 0 : _a[0].location_id) || 0,
                                })];
                        case 1:
                            response = _c.sent();
                            if (!(response.length != 0)) return [3 /*break*/, 3];
                            return [4 /*yield*/, DocumentStateLocationService.update(response[0].id || 0, __assign(__assign({}, response[0]), { state: state }))];
                        case 2:
                            _c.sent();
                            return [2 /*return*/];
                        case 3:
                            data = {
                                id: 0,
                                history_id: history.id,
                                document_id: this.detailProp.document_id,
                                location_id: ((_b = user.users_locations) === null || _b === void 0 ? void 0 : _b[0].location_id) || 0,
                                state: state,
                                state_read: "no leido",
                            };
                            return [4 /*yield*/, DocumentStateLocationService.create(data)];
                        case 4:
                            _c.sent();
                            return [3 /*break*/, 6];
                        case 5:
                            error_4 = _c.sent();
                            console.log(error_4);
                            throw error_4;
                        case 6: return [2 /*return*/];
                    }
                });
            });
        },
        loadStablishments: function () {
            return __awaiter(this, void 0, void 0, function () {
                var stablishmentsResponse, error_5;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.loadingStablishments = true;
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, , 4]);
                            this.stablishments.push({
                                id: 0,
                                name: "Todos",
                            });
                            return [4 /*yield*/, StablishmentService.getAll()];
                        case 2:
                            stablishmentsResponse = _a.sent();
                            this.stablishments = __spreadArrays(this.stablishments, stablishmentsResponse);
                            return [3 /*break*/, 4];
                        case 3:
                            error_5 = _a.sent();
                            console.log(error_5);
                            return [3 /*break*/, 4];
                        case 4:
                            this.loadingStablishments = false;
                            return [2 /*return*/];
                    }
                });
            });
        } }),
});
