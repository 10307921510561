import { __awaiter, __generator } from "tslib";
import Vue from "vue";
import { PurchaseRequestStateService } from "@/services/models/purchaseRequest/purchaseRequestState";
export default Vue.extend({
    props: {
        purchaseProp: {
            type: Object,
        },
    },
    data: function () {
        return {
            dialog: false,
            isLoading: false,
            dataResponsables: [],
            headers: [
                {
                    text: "Funcionario",
                    sortable: false,
                    value: "user",
                },
                {
                    text: "Estado",
                    sortable: false,
                    value: "state",
                },
            ],
        };
    },
    mounted: function () {
        this.loadData();
    },
    methods: {
        loadData: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            this.isLoading = true;
                            _a = this;
                            return [4 /*yield*/, PurchaseRequestStateService.getByPurchaseRequestId(this.purchaseProp.id)];
                        case 1:
                            _a.dataResponsables = _b.sent();
                            this.isLoading = false;
                            return [2 /*return*/];
                    }
                });
            });
        }
    }
});
