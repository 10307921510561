import { __assign, __awaiter, __generator } from "tslib";
import Vue from "vue";
import { FolderService } from "@/services/models/document/folder";
import { mapActions } from "vuex";
export default Vue.extend({
    name: "ButtonDeleteFolder",
    props: {
        folderId: {
            required: true
        }
    },
    data: function () {
        return {
            dialog: false,
        };
    },
    methods: __assign(__assign({}, mapActions({
        getFolders: "folders/getFolder",
    })), { removeFolder: function () {
            return __awaiter(this, void 0, void 0, function () {
                var error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 4, 5, 6]);
                            this.displayLoader(true);
                            return [4 /*yield*/, FolderService.remove(this.folderId)];
                        case 1:
                            _a.sent();
                            return [4 /*yield*/, this.getFolders(this.getCurrentUserLoggedIn().id)];
                        case 2:
                            _a.sent();
                            this.dialog = false;
                            return [4 /*yield*/, this.$router.push({ name: 'administratorHome' })];
                        case 3:
                            _a.sent();
                            return [3 /*break*/, 6];
                        case 4:
                            error_1 = _a.sent();
                            console.log(error_1);
                            return [3 /*break*/, 6];
                        case 5:
                            this.displayLoader(false);
                            return [7 /*endfinally*/];
                        case 6: return [2 /*return*/];
                    }
                });
            });
        } })
});
