import Vue from "vue";
export default Vue.extend({
    props: {
        document_detail: {
            required: true,
        }
    },
    data: function () {
        return {};
    },
});
