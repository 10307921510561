import { __assign } from "tslib";
import Vue from "vue";
import Inputs from "./FiltersSearchAdvanced.vue";
import { mapActions } from "vuex";
import TableSearchDocument from "./TableSearchDocument..vue";
import store from "@/store";
export default Vue.extend({
    components: {
        Inputs: Inputs,
        TableSearchDocument: TableSearchDocument,
    },
    data: function () {
        return {
            dialog2: false,
            dialog: false,
            isLoading: false,
        };
    },
    watch: {
        dialog: function () {
            if (this.dialog) {
                console.log("asdasd");
            }
        },
    },
    methods: __assign(__assign({}, mapActions({
        documentSearch: 'documents/getSearchDocument',
        addFilter: 'documents/setFilterSearchAdvanced'
    })), { search: function () {
            var DATA = __assign({}, this.$refs.inputDataUser.document);
            store.commit('documents/setFilterSearchAdvanced', DATA);
            this.documentSearch();
            this.dialog2 = true;
        } }),
});
