import { __awaiter, __generator } from "tslib";
import Vue from "vue";
import { DocumentDetailService } from "@/services/models/document/documentDetail";
import ValidateDataDocument from "@/components/vaildateDocument/ValidateDataDocument.vue";
import ValidateExpandedPanel from "@/components/vaildateDocument/ValidateExpandedPanel.vue";
export default Vue.extend({
    components: { ValidateDataDocument: ValidateDataDocument, ValidateExpandedPanel: ValidateExpandedPanel },
    data: function () {
        return {
            document_id: null,
            verification_code: null,
            loading: false,
            document_detail: []
        };
    },
    mounted: function () {
        if (this.$route.query.id != null && this.$route.query.codigo != null) {
            this.validateDocument();
        }
    },
    computed: {
        showMenu: function () {
            var asd = false;
            asd = this.$route.query.id == null || this.$route.query.codigo == null;
            return asd;
        }
    },
    methods: {
        searchDocument: function () {
            this.displayLoader(true);
            if (!this.document_id && !this.verification_code) {
                return;
            }
            this.$router.push({ name: 'Validador', query: {
                    id: this.document_id,
                    codigo: this.verification_code
                } });
            this.validateDocument();
            this.displayLoader(false);
        },
        validateDocument: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            this.displayLoader(true);
                            _a = this;
                            return [4 /*yield*/, DocumentDetailService.validate(this.$route.query.id, this.$route.query.codigo)];
                        case 1:
                            _a.document_detail = _b.sent();
                            this.displayLoader(false);
                            return [2 /*return*/];
                    }
                });
            });
        },
        returnValidator: function () {
            this.$router.push({ name: 'Validador' });
        }
    }
});
