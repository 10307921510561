import { __spreadArrays } from "tslib";
import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login.vue';
import Validator from '../views/ValidatorSearch.vue';
import { routes as Administrator } from './administrator';
import { routes as Administrative } from './administrative';
import { routes as Opartes } from './opartes';
import EmptyRouterView from "@/views/EmptyRouterView.vue";
Vue.use(VueRouter);
var routes = __spreadArrays([
    {
        path: '/',
        name: 'Login',
        component: Login
    }
], Administrator, Administrative, Opartes, [
    {
        path: '/',
        component: EmptyRouterView,
        children: [
            {
                path: 'validador',
                name: 'Validador',
                component: Validator,
            },
        ]
    }
]);
var router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes
});
export default router;
