import { __assign } from "tslib";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import EditDocument from "../EditDocument.vue";
import ShowDocumentDetail from "../ShowDocument/ShowDocumentDetail.vue";
import ButtonChangeRead from "../ButtonChangeRead.vue";
export default Vue.extend({
    components: {
        ShowDocumentDetail: ShowDocumentDetail,
        EditDocument: EditDocument,
        ButtonChangeRead: ButtonChangeRead,
    },
    data: function () {
        return {
            page: 1,
            headers: [
                {
                    text: "ID",
                    align: "start",
                    value: "id",
                    sortable: false,
                },
                { text: "Número", sortable: false, align: "start", value: "number" },
                {
                    text: "Descripcion",
                    sortable: false,
                    align: "start",
                    value: "description",
                },
                {
                    text: "Tipo de Documento",
                    sortable: false,
                    align: "start",
                    value: "document_type.name",
                },
                {
                    text: "Depto.",
                    sortable: false,
                    align: "start",
                    value: "location.name",
                },
                { text: "Fecha", sortable: false, align: "start", value: "created_at" },
                {
                    text: "Última Actualización",
                    sortable: false,
                    align: "start",
                    value: "updated_at",
                },
                { text: "Acciones", sortable: false, align: "start", value: "action" },
            ],
        };
    },
    computed: __assign({}, mapGetters({
        getDocuments: "documents/getSearchDocument",
        getLoading: "documents/getLoading",
    })),
    methods: __assign(__assign({}, mapActions({
        documentSearch: 'documents/getSearchDocument'
    })), { stateColor: function (state) {
            var classColor = "";
            switch (state) {
                case "recibido":
                    classColor = "primary lighten-4";
                    break;
                case "enviado":
                    classColor = "yellow lighten-4";
                    break;
                default:
                    classColor = "";
                    break;
            }
            return classColor;
        },
        changePage: function () {
            this.$store.commit("documents/setPageSearchDocument", this.page);
            this.documentSearch();
        } }),
});
